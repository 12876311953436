import React from 'react';
import { hot } from 'react-hot-loader/root';
import history from './history';
import { Router, Route } from 'react-router-dom';

/* ---[ Components and views ]--- */
import Login from '../Login/Login.jsx';
import Projects from '../Projects/Projects.jsx';
import Loading from '../../views/Loading/Loading.jsx';
import { app_reducer } from '../../reducers/reducers';

export const ProjectContext = React.createContext({
  state: {},
  dispatch: () => { },
});

const App = () => {
  const [state, dispatch] = React.useReducer(app_reducer, {
    active_tool: null,
    points: [],
    labels: [],
    polylines: [],
    current_project: null,
    state_updating: false,
    project_private: false,
  });

  return (
    <Router history={history}>
      <ProjectContext.Provider value={{ state, dispatch }}>
        <Route exact path='/login' component={Login} />
        <Route exact path='/' component={Projects} />
        <Route exact path='/project/:id' component={Loading} />
      </ProjectContext.Provider>
    </Router>
  );
};

export default hot(App);
