import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/* ---[ Components ]--- */
import Modal from '../Modal/Modal.jsx';

/* ---[ Iconography ]--- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

/* ---[ Presentation ]--- */
import Logo from '../../../svg/logo-swescan.svg';
import classNames from 'classnames/bind';
import styles from './Navbar.module.scss';
const style = classNames.bind(styles);

const Navbar = ({ history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState('modal is-close');
  const [newProjectModal, setNewProjectModal] = useState(null);

  const openBurger = () => {
    setIsOpen(!!isOpen);
  };

  const logOut = () => {
    localStorage.removeItem('token');
    history.push('/login');
  };

  const openModal = () => {
    setModalStatus('modal is-active');
    setNewProjectModal(true);
  };

  const closeModal = (e) => {
    setModalStatus('modal is-close');
    e.preventDefault();
  };

  return (
    <>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="container">
          <div className="navbar-brand">
            <a className="navbar-item">
              <Logo className={style('logo')}/>
            </a>

            <a role="button" className="navbar-burger is-widescreen" onClick={openBurger}>
              {isOpen ? <div className='button is-light' onClick={logOut}> Logga ut </div> : null}
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start">
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">
                  Projektöversikt
                </a>

                <div className="navbar-dropdown">
                  <a className="navbar-item" onClick={openModal}>
                    <span className="icon">
                      <FontAwesomeIcon icon={faPlus} />
                    </span>
                    <span>Lägg till projekt</span>
                  </a>
                </div>
              </div>
            </div>

            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons" onClick={logOut}>
                  <a className="button is-light">
                    Logga ut
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Modal
        modalStatus={modalStatus}
        close={closeModal}
        abort={closeModal}
        modalTitle='Skapa Projekt'
        modalType={newProjectModal}
        setWhatModal={false}
      />
    </>
  );
};

Navbar.propTypes = {
  client: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(Navbar);
