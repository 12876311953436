export const ActionTypes = {
  SET_ACTIVE_TOOL: Symbol('Set the active tool'),

  ADD_POINT: Symbol('Add a Resium point to the viewer'),
  REMOVE_POINT: Symbol('Remove a Resium point from the viewer based on React key'),
  REMOVE_POINTS: Symbol('Remove all Resium points from the viewer'),

  ADD_LABEL: Symbol('Add a Resium label to the viewer'),
  REMOVE_LABEL: Symbol('Remove a Resium label from the viewer based on React key'),
  REMOVE_LABELS: Symbol('Remove all Resium labels from the viewer'),

  ADD_POLYLINE: Symbol('Add a Resium polyline to the viewer.'),
  REMOVE_POLYLINE: Symbol('Remove a polyline point from the viewer based on React key'),
  REMOVE_POLYLINES: Symbol('Remove all Resium polylines from the viewer'),

  REMOVE_ALL: Symbol('Remove all Resium components from the viewer'),
  UPDATE_PROJECT: Symbol('Add a client name'),
  STATE_IS_UPDATING: Symbol('State is currently updating'),
  UPDATE_PROJECT_PRIVACY: Symbol('Checking if project is private or public, and updating state'),
};

export const setActiveTool = (tool) => ({ type: ActionTypes.SET_ACTIVE_TOOL, payload: tool });

export const addPoint = (point) => ({ type: ActionTypes.ADD_POINT, payload: point });
export const removePoint = (key) => ({ type: ActionTypes.REMOVE_POINT, payload: key });
export const removePoints = () => ({ type: ActionTypes.REMOVE_POINTS });

export const addLabel = (label) => ({ type: ActionTypes.ADD_LABEL, payload: label });
export const removeLabel = (key) => ({ type: ActionTypes.REMOVE_LABEL, payload: key });
export const removeLabels = () => ({ type: ActionTypes.REMOVE_LABELS });

export const addPolyline = (polyline) => ({ type: ActionTypes.ADD_POLYLINE, payload: polyline });
export const removePolyline = (key) => ({ type: ActionTypes.REMOVE_POLYLINE, payload: key });
export const removePolylines = () => ({ type: ActionTypes.REMOVE_POLYLINES });

export const removeAll = () => ({ type: ActionTypes.REMOVE_ALL });
export const setCurrentProject = (value) => ({ type: ActionTypes.UPDATE_PROJECT, value });
export const updateState = (value) => ({ type: ActionTypes.STATE_IS_UPDATING, value });
export const updatePrivacy = (value) => ({ type: ActionTypes.UPDATE_PROJECT_PRIVACY, value });
