// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".tools__3P0w_{width:3rem;height:auto;overflow:hidden;border-radius:4px;background:#fff;position:absolute;top:6.5rem;left:2.5rem}.tools__button__32h04{width:3rem;height:3rem;margin:0 0;padding:0 0;border:none;outline:none;display:flex;cursor:pointer;background:none;align-items:center;justify-content:center}.tools__button__32h04:not(:last-child){border-bottom:1px solid #e3e3e3}.tools__icon__2IhEe{width:1.25rem;height:1.25rem;fill:#888;transition:all 200ms ease-in-out}.tools__button--active__1TsLG .tools__icon__2IhEe{fill:#ec6700}\n", "",{"version":3,"sources":["Tools.module.scss"],"names":[],"mappings":"AAAA,cAAO,UAAU,CAAC,WAAW,CAAC,eAAe,CAAC,iBAAiB,CAAC,eAAe,CAAC,iBAAiB,CAAC,UAAU,CAAC,WAAW,CAAC,sBAAe,UAAU,CAAC,WAAW,CAAC,UAAU,CAAC,WAAW,CAAC,WAAW,CAAC,YAAY,CAAC,YAAY,CAAC,cAAc,CAAC,eAAe,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,uCAAgC,+BAA+B,CAAC,oBAAa,aAAa,CAAC,cAAc,CAAC,SAAS,CAAC,gCAAgC,CAAC,kDAAoC,YAAY","file":"Tools.module.scss","sourcesContent":[".tools{width:3rem;height:auto;overflow:hidden;border-radius:4px;background:#fff;position:absolute;top:6.5rem;left:2.5rem}.tools__button{width:3rem;height:3rem;margin:0 0;padding:0 0;border:none;outline:none;display:flex;cursor:pointer;background:none;align-items:center;justify-content:center}.tools__button:not(:last-child){border-bottom:1px solid #e3e3e3}.tools__icon{width:1.25rem;height:1.25rem;fill:#888;transition:all 200ms ease-in-out}.tools__button--active .tools__icon{fill:#ec6700}\n"]}]);
// Exports
exports.locals = {
	"tools": "tools__3P0w_",
	"tools__button": "tools__button__32h04",
	"tools__icon": "tools__icon__2IhEe",
	"tools__button--active": "tools__button--active__1TsLG"
};
module.exports = exports;
