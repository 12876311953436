// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".pagination-wrapper__1sk3H{display:flex;width:100%;margin:0;padding:0;align-items:center}.pagination-wrapper__1sk3H li{box-sizing:border-box}.pagination-wrapper__1sk3H li:first-child{margin-right:auto}.pagination-wrapper__1sk3H li:last-child{margin-left:auto}.table__head__27sdW{cursor:pointer}.table__container__2lMFU{display:flex;align-items:center}.table__arrow__30ZC3{width:.6rem;height:.6rem;margin-left:1rem}.table__arrow--down__3htEX{transform:rotate(180deg)}\n", "",{"version":3,"sources":["Projects.module.scss"],"names":[],"mappings":"AAAA,2BAAoB,YAAY,CAAC,UAAU,CAAC,QAAQ,CAAC,SAAS,CAAC,kBAAkB,CAAC,8BAAuB,qBAAqB,CAAC,0CAAmC,iBAAiB,CAAC,yCAAkC,gBAAgB,CAAC,oBAAa,cAAc,CAAC,yBAAkB,YAAY,CAAC,kBAAkB,CAAC,qBAAc,WAAW,CAAC,YAAY,CAAC,gBAAgB,CAAC,2BAAoB,wBAAwB","file":"Projects.module.scss","sourcesContent":[".pagination-wrapper{display:flex;width:100%;margin:0;padding:0;align-items:center}.pagination-wrapper li{box-sizing:border-box}.pagination-wrapper li:first-child{margin-right:auto}.pagination-wrapper li:last-child{margin-left:auto}.table__head{cursor:pointer}.table__container{display:flex;align-items:center}.table__arrow{width:.6rem;height:.6rem;margin-left:1rem}.table__arrow--down{transform:rotate(180deg)}\n"]}]);
// Exports
exports.locals = {
	"pagination-wrapper": "pagination-wrapper__1sk3H",
	"table__head": "table__head__27sdW",
	"table__container": "table__container__2lMFU",
	"table__arrow": "table__arrow__30ZC3",
	"table__arrow--down": "table__arrow--down__3htEX"
};
module.exports = exports;
