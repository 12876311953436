import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/* ---[ Presentation ]--- */
import classNames from 'classnames/bind';
import styles from './ProjectBar.module.scss';
const style = classNames.bind(styles);

const Navbar = (props) => {
  moment.locale('sv');
  return (
    <div className={style('projectbar')}>
      <div className={style('projectbar__titles')}>
        <h2 className={style('projectbar__customer')}>{props.client}</h2>
        <h2 className={style('projectbar__customer')}>{props.title}</h2>
        <h2 className={style('projectbar__customer')}>{props.date ? moment(props.date).format('DD MMMM YYYY') : ''}</h2>
      </div>
      <small className={style('projectbar__description')}>Swescan 3D Viewer</small>
    </div>
  );
};

Navbar.propTypes = {
  title: PropTypes.string,
  client: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
};

export default Navbar;
