// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../public/img/background.jpg");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".loading__1r6eS{width:100vw;height:100vh;display:flex;align-items:center;flex-direction:column;justify-content:center;background-size:cover;background-image:linear-gradient(#fff, rgba(255,255,255,0.8)),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.loading__logo__nfw_m{height:auto;width:18.75rem;margin-bottom:2.5rem}.loading__title__BNvw0{font-weight:700;margin-bottom:1.5rem}.loading__placeholder__K5SgM{margin-bottom:1.5rem}.loading__button__1Lh9U{width:100%;height:48px;background:#3298dc;border-color:transparent;border-radius:4px;color:#fff;margin-top:10px}.loading__button__1Lh9U:hover{background:#7ec7f7;cursor:pointer}.loading__button__1Lh9U:focus{outline:none}\n", "",{"version":3,"sources":["Loading.module.scss"],"names":[],"mappings":"AAAA,gBAAS,WAAW,CAAC,YAAY,CAAC,YAAY,CAAC,kBAAkB,CAAC,qBAAqB,CAAC,sBAAsB,CAAC,qBAAqB,CAAC,qGAA0G,CAAC,sBAAe,WAAW,CAAC,cAAc,CAAC,oBAAoB,CAAC,uBAAgB,eAAe,CAAC,oBAAoB,CAAC,6BAAsB,oBAAoB,CAAC,wBAAiB,UAAU,CAAC,WAAW,CAAC,kBAAkB,CAAC,wBAAwB,CAAC,iBAAiB,CAAC,UAAU,CAAC,eAAe,CAAC,8BAAuB,kBAAkB,CAAC,cAAc,CAAC,8BAAuB,YAAY","file":"Loading.module.scss","sourcesContent":[".loading{width:100vw;height:100vh;display:flex;align-items:center;flex-direction:column;justify-content:center;background-size:cover;background-image:linear-gradient(#fff, rgba(255,255,255,0.8)),url(\"../../../../public/img/background.jpg\")}.loading__logo{height:auto;width:18.75rem;margin-bottom:2.5rem}.loading__title{font-weight:700;margin-bottom:1.5rem}.loading__placeholder{margin-bottom:1.5rem}.loading__button{width:100%;height:48px;background:#3298dc;border-color:transparent;border-radius:4px;color:#fff;margin-top:10px}.loading__button:hover{background:#7ec7f7;cursor:pointer}.loading__button:focus{outline:none}\n"]}]);
// Exports
exports.locals = {
	"loading": "loading__1r6eS",
	"loading__logo": "loading__logo__nfw_m",
	"loading__title": "loading__title__BNvw0",
	"loading__placeholder": "loading__placeholder__K5SgM",
	"loading__button": "loading__button__1Lh9U"
};
module.exports = exports;
