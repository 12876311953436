import React from 'react';

const ErrorText = () => {
  return (
    <tr>
      <td colSpan={5}>Inga projekt motsvarar dina sökord.</td>
    </tr>
  );
};

export default ErrorText;
