import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ProjectContext } from '../App/App.jsx';
import { updatePrivacy, setCurrentProject } from '../../actions/actions.js';
import { useParams } from 'react-router-dom';

/* ---[ Components ]--- */
import Logo from '../../../svg/logo-swescan.svg';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Project from '../../components/Project/Project.jsx';

/* ---[ Iconography ]--- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

/* ---[ Presentation ]--- */
import classNames from 'classnames/bind';
import styles from './Loading.module.scss';
const style = classNames.bind(styles);

const Loading = () => {
  const chosenProject = useParams();
  const project_context = React.useContext(ProjectContext);
  const [loading, setLoading] = useState(true);
  const [hasPassword, setHasPassword] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const [inputValStyle, setInputValStyle] = useState('input');

  /**
  * Fetches one project by Id and check if it has a password.
  */
  useEffect(() => {
    const getProject = async() => {
      await axios.get(`/api/projects/${chosenProject.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          project_context.dispatch(setCurrentProject(response.data));
          if (response.data.has_password) {
            project_context.dispatch(updatePrivacy(true));
            setTimeout(function() {
              setHasPassword(true);
            }, 3000);
          } else {
            project_context.dispatch(updatePrivacy(false));
            setTimeout(function() {
              setLoading(false);
            }, 2200);
          }
        }
      });
    };
    getProject();
  }, []);

  /**
   * Updates the local InputVal state.
   * @param {*} event
   */
  const handleChange = (event) => {
    setInputVal(event.target.value);
  };

  /**
   * Sends a request to backend to see
   * if the project password is the same as the user input value.
   */
  const handlePassword = (e) => {
    e.preventDefault();
    axios.post(`/api/projects/${chosenProject.id}`, {
      password: inputVal,
    }).then(response => {
      if (response.status === 202) {
        setInputValStyle('input');
        setLoading(false);
      } else {
        setInputValStyle('input is-danger');
      }
    }).catch(err => {
      setInputValStyle('input is-danger');
      console.log(err);
    });
  };

  const loadingView = (
    <div className={style('loading')}>
      <Logo className={style('loading__logo')} />
      {
        hasPassword
          ? <form onSubmit={handlePassword}>
            <p className={style('loading__title')}>Detta projekt är lösenordsskyddat</p>
            <p className="control has-icons-left">
              <input className={inputValStyle} type="password" placeholder="Lösenord..." onChange={handleChange} />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faLock} />
              </span>
              <button className={style('loading__button')} onClick={handlePassword}>
                Gå till Projekt
              </button>
            </p>
          </form>
          : <>
            <p className={style('loading__placeholder')}>Laddar projekt...</p>
            <ScaleLoader color={'#ffa500'} />
          </>
      }
    </div>
  );

  return (loading ? loadingView : <Project />);
};

export default Loading;
