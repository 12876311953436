import React from 'react';
import { ProjectContext } from '../../views/App/App.jsx';
import { ToolTypes } from '../../utils/tools';
import { Cartesian3, ScreenSpaceEventType, Cartographic } from 'cesium';
import { CesiumContext, ScreenSpaceEventHandler, ScreenSpaceEvent } from 'resium';
import { setActiveTool, addPoint, addPolyline, addLabel, removeAll } from '../../actions/actions';
import { createPoint, createPolyline, createLabel, updatePointData, calculateLabelOffset, getDistanceString, getMidpoint } from '../../utils/utils.jsx';

/* ---[ Presentation ]--- */
import Tippy from '@tippyjs/react';
import Icon from '../../../svg/tool-distance.svg';
import classNames from 'classnames/bind';
import styles from './Tools.module.scss';
const style = classNames.bind(styles);

/* ---[ Declarations ]--- */
const TOOL_NAME = ToolTypes.DISTANCE_TOOL;

/**
 * Distance Tool
 * @returns {*}
 * @constructor
 */
const DistanceTool = () => {
  const project_context = React.useContext(ProjectContext);
  const cesium_context = React.useContext(CesiumContext);

  const [state, setState] = React.useState({
    positions: [],
    click_count: 0,
  });

  const is_active = project_context.state.active_tool === TOOL_NAME;

  /**
   * Enable this tool.
   */
  const enable = () => {
    resetState();
    project_context.dispatch(removeAll());
    project_context.dispatch(setActiveTool(TOOL_NAME));
  };

  /**
   * Disable this tool.
   */
  const disable = () => {
    resetState();
    project_context.dispatch(removeAll());
    project_context.dispatch(setActiveTool(null));
  };

  /**
   * Toggle the tool active status and update state.
   */
  const toggle = () => (is_active ? disable() : enable());

  /**
   * Reset the state back to initial mode.
   */
  const resetState = () => setState({
    positions: [],
    click_count: 0,
  });

  /**
   * Handle left mouse click.
   * @param cartesian2
   */
  const handleLeftClick = cartesian2 => {
    const click_position = cesium_context.viewer.scene.pickPosition(cartesian2.position);
    const position = new Cartesian3(click_position.x, click_position.y, click_position.z);

    if (state.click_count === 0) {
      const point = createPoint(position);
      project_context.dispatch(addPoint(point));

      setState({
        positions: [...state.positions, position],
        click_count: state.click_count += 1,
      });
    } else if (state.click_count === 1) {
      const point = createPoint(position);

      const geo_positions = [
        Cartographic.fromCartesian(state.positions[0]),
        Cartographic.fromCartesian(position),
      ];

      const polyline = createPolyline('Color', [
        new Cartesian3.fromRadians(geo_positions[0].longitude, geo_positions[0].latitude, geo_positions[0].height),
        new Cartesian3.fromRadians(geo_positions[1].longitude, geo_positions[1].latitude, geo_positions[1].height),
      ]);

      const point_1 = updatePointData(state.positions[0]);
      const point_2 = updatePointData(position);
      const height = calculateLabelOffset(geo_positions[0], geo_positions[1]);

      const label_text = getDistanceString(point_1, point_2, geo_positions[0], geo_positions[1]);
      const label_position = getMidpoint(point_1, point_2, height);
      const label = createLabel(label_text, label_position);

      project_context.dispatch(addPoint(point));
      project_context.dispatch(addPolyline(polyline));
      project_context.dispatch(addLabel(label));

      setState({
        positions: [...state.positions, position],
        click_count: state.click_count += 1,
      });
    } else if (state.click_count === 2) {
      project_context.dispatch(removeAll());
      resetState();
    }
  };

  const classes = style('tools__button', {
    'tools__button--active': is_active,
  });

  return (
    <>
      <Tippy content="Distance Tool" placement="right" offset={[0, 16]}>
        <button className={classes} onClick={toggle}>
          <Icon className={style('tools__icon')} />
        </button>
      </Tippy>
      {
        is_active &&
        <ScreenSpaceEventHandler>
          <ScreenSpaceEvent action={handleLeftClick} type={ScreenSpaceEventType.LEFT_CLICK} />
        </ScreenSpaceEventHandler>
      }
    </>
  );
};

export default DistanceTool;
