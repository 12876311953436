import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { loginDraft } from './state';
import axios from 'axios';

/* ---[ Iconography ]--- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';

/* ---[ Presentation ]--- */
import Logo from '../../../svg/logo-swescan.svg';
import classNames from 'classnames/bind';
import styles from './Login.module.scss';
const style = classNames.bind(styles);

const Start = () => {
  const [state, setState] = useState(loginDraft);
  const [inputStyling, setInputStyling] = useState('input');
  const history = useHistory();

  /**
   * Handles the change on inputs and updates the local state.
   * @param {*} event
   */
  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  /**
   * Makes a login request to the backend for authentication.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/api/authenticate', {
      username: state.username,
      password: state.password,
    }).then((response) => {
      if (response.status === 200) {
        setInputStyling('input is-medium');
        const token = response.data.token;
        localStorage.setItem('token', token);
        history.push('/');
      }
      return response;
    }).catch(error => {
      setInputStyling('input is-danger');
      console.log(error);
    });
  };

  const LoginForm =
    (
      <>
        <form onSubmit={handleSubmit}>
          <div className={style('form-wrapper')}>
            <p className="control has-icons-left has-icons-right">
              <input className="input" type="username" placeholder="Användarnamn" onChange={handleChange} name="username" />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faUser} />
              </span>
            </p>
          </div>
          <div className={style('form-wrapper')}>
            <p className="control has-icons-left">
              <input className={inputStyling} type="password" placeholder="Lösenord" onChange={handleChange} name="password" />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faLock} />
              </span>
            </p>
          </div>
          <div className={style('form-wrapper')}>
            <p className="control">
              <button className="button is-info is-fullwidth" onClick={handleSubmit}>
                Logga in
              </button>
            </p>
          </div>
        </form>
      </>
    );

  return (
    <div className={style('login')}>
      <Logo className={style('login__logo')} />
      {LoginForm}
    </div>
  );
};

export default Start;
