import React from 'react';
import PropTypes from 'prop-types';

/* ---[ Presentation ]--- */
import classNames from 'classnames/bind';
import styles from './Tools.module.scss';
const style = classNames.bind(styles);

const Tools = (props) => {
  return (
    <div className={style('tools')}>
      {props.children}
    </div>
  );
};

Tools.propTypes = {
  children: PropTypes.any,
};

export default Tools;
