import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

/* ---[ Iconography ]--- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEye, faWrench, faTrash } from '@fortawesome/free-solid-svg-icons';

const ProjectInfo = (props) => {
  return (
    <tr>
      <td>{props.title}</td>
      <td>{props.client}</td>
      <td>{props.description}</td>
      <td>{props.date ? moment(props.date).format('YYYY-MM-DD') : ''}</td>
      <td>{props.timestamp ? moment(props.timestamp).format('YYYY-MM-DD') : ''}</td>
      <td className={props.protected}>
        <span className="icon">
          <FontAwesomeIcon icon={faLock} />
        </span>
        {props.public}
      </td>
      <td className="has-text-right">
        <Link to={'/project/' + props.id} target="_blank">
          <div className="button is-small mr-2" name={props.name}>
            <span className="icon is-small">
              <FontAwesomeIcon icon={faEye} />
            </span>
          </div>
        </Link>

        <div className="button is-small is-link mr-2" onClick={props.editProject} name={props.name}>
          <span className="icon is-small">
            <FontAwesomeIcon icon={faWrench} />
          </span>
        </div>

        <div className="button is-small is-danger" onClick={props.remove} name={props.name}>
          <span className="icon is-small" onClick={props.remove} name={props.name}>
            <FontAwesomeIcon icon={faTrash} />
          </span>
        </div>
      </td>
    </tr>
  );
};

ProjectInfo.propTypes = {
  client: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  timestamp: PropTypes.string,
  date: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  remove: PropTypes.func,
  public: PropTypes.string,
  protected: PropTypes.string,
  editProject: PropTypes.func,
};

export default ProjectInfo;
