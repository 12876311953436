import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AxiosInterceptor from '../../utils/interceptor';
import { projectDraft } from './state.js';
import { v4 as uuidv4 } from 'uuid';
import { ProjectContext } from '../../views/App/App.jsx';
import { updateState } from '../../actions/actions';
import axios from 'axios';
import moment from 'moment';

/* ---[ Iconography ]--- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faLock } from '@fortawesome/free-solid-svg-icons';

const Modal = (props) => {
  const [projectState, setProjectState] = useState(projectDraft);
  const [validation, setValidation] = useState({ title: 'input', client: 'input', date: 'input', url: 'input' });
  const project_context = React.useContext(ProjectContext);
  const [validationDisable, setValidationDisable] = useState(true);
  const [editState, setEditState] = useState(projectDraft);

  AxiosInterceptor.bind_authentication_middleware();
  const accessString = localStorage.getItem('token');

  /**
   * Checks input validation, if correct, updates state.
   */
  useEffect(() => {
    const targetVal = Object.values(validation);
    if (targetVal[0] === 'input is-success' && targetVal[1] === 'input is-success' && targetVal[2] === 'input is-success') {
      setValidationDisable(false);
    } else {
      setValidationDisable(true);
    }
  }, [validation]);

  /**
   * Sets values if your going to edit a project
   * this so user sees what the project properties already are.
   */
  useEffect(() => {
    if (props.modalTitle === 'Redigera Projekt') {
      setEditState({
        ...editState,
        title: project_context.state.current_project.title,
        description: project_context.state.current_project.description,
        date: project_context.state.current_project.date,
        client: project_context.state.current_project.client,
        url: project_context.state.current_project.cesium_url,
        password: project_context.state.current_project.password,
        id: project_context.state.current_project.id,
      });
    }
    if (props.modalTitle === 'Skapa Projekt') {
      setProjectState(projectDraft);
    }
  }, [props.modalTitle, project_context]);

  /**
   * Takes all the information from the local state
   * and sends a POST request with it to create a project.
   * @param {*} e
   */
  const addNewProject = (e) => {
    e.preventDefault();

    const currentDate = moment();

    const data = {
      client: projectState.client,
      title: projectState.title,
      cesium_url: projectState.url,
      description: projectState.description,
      date: projectState.date,
      password: projectState.password,
      id: uuidv4(),
      timestamp: currentDate.format('YYYY-MM-DD HH:mm:ss'),
    };

    const headers = {
      'Content-Type': 'application/json',
      'Accept': '*',
      Authorization: `JWT ${accessString}`,
    };

    axios.post('/api/projects', data, {
      headers: headers,
    }).then((response) => {
      if (response.status === 201) {
        project_context.dispatch(updateState(true));
        setValidation({ title: 'input', client: 'input', url: 'input' });
      }
    }).catch(error => console.log(error));
  };

  /**
   * Takes the new information that the user
   * puts in and sends a request to update a project.
   * @param {*} e
   */
  const editProject = (e) => {
    e.preventDefault();
    const data = {
      client: editState.client,
      title: editState.title,
      cesium_url: editState.url,
      description: editState.description,
      date: moment(editState.date).format('YYYY-MM-DD H:m'),
      password: editState.password,
      id: editState.id,
    };

    const headers = {
      'Content-Type': 'application/json',
      'Accept': '*',
      Authorization: `JWT ${accessString}`,
    };

    axios.put(`/api/projects/${editState.id}`, data, {
      headers: headers,
    }).then((response) => {
      if (response.status === 200) {
        project_context.dispatch(updateState(true));
      }
    }).catch(error => console.log(error));
  };

  /**
   * Simple function that handles the local state
   * and updates every input field depending on what
   * name it has.
   * @param {*} event
   */
  const handleInputs = (event) => {
    const { name, value, date } = event.target;
    if (props.modalTitle === 'Redigera Projekt') {
      setEditState({
        ...editState,
        [name]: value.length < 0 ? editState.name : value,
        [date]: new Date(value),
      });
    }
    if (props.modalTitle === 'Skapa Projekt') {
      setProjectState({
        ...projectState,
        [name]: value,
        [date]: new Date(value),
      });
    };

    setValidation({
      ...validation,
      [name]: value.length > 2 ? 'input is-success' : 'input is-danger',
    });
  };

  return (
    <>
      {props.removeOrAddEdit ? <div className={props.modalStatus}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head has-background-danger">
            <p className="modal-card-title has-text-white">Radera projekt</p>
            <button className="delete" aria-label="close" onClick={props.close}></button>
          </header>
          <form action="" method="post">
            <section className="modal-card-body">
              <p>Vill du verkligen radera projektet <strong>{project_context.state.current_project.title}</strong>? Denna åtgärd kan inte ångras!</p>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-danger" onClick={props.delete}>Radera</button>
              <button className="button" onClick={props.abort}>Avbryt</button>
            </footer>
          </form>
        </div>
      </div>
        : <div className={props.modalStatus}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">{props.modalTitle}</p>
              <button className="delete" aria-label="close" onClick={props.close}></button>
            </header>
            <form action="" method="post">
              <section className="modal-card-body">
                <div className="field">
                  <label className="label">Projektnamn <span className="has-text-danger">*</span></label>
                  <div className="control">
                    {props.modalTitle === 'Redigera Projekt' ? <input className={validation.title} value={editState.title} type="text" placeholder="T.ex. H22 Scanning" name="title" onChange={handleInputs} /> : null}
                    {props.modalTitle === 'Skapa Projekt' ? <input className={validation.title} value={projectState.title} type="text" placeholder="T.ex. H22 Scanning" name="title" onChange={handleInputs} /> : null}
                  </div>
                </div>

                <div className="field">
                  <label className="label">Beskrivning</label>
                  <div className="control">
                    {props.modalTitle === 'Redigera Projekt' ? <input className="input" value={editState.description} type="text" placeholder="Ange en kort beskrivning här om du vill visa en" name="description" onChange={handleInputs} /> : null}
                    {props.modalTitle === 'Skapa Projekt' ? <input className="input" value={projectState.description} type="text" placeholder="Ange en kort beskrivning här om du vill visa en" name="description" onChange={handleInputs} /> : null}
                  </div>
                </div>

                <div className="field">
                  <label className="label">Datum<span className="has-text-danger">*</span></label>
                  <div className="control">
                    {props.modalTitle === 'Redigera Projekt' ? <input className={validation.date} value={editState.date ? moment(editState.date).format('YYYY-MM-DD') : ''} type="date" placeholder="Ange ett datum" name="date" onChange={handleInputs} /> : null}
                    {props.modalTitle === 'Skapa Projekt' ? <input className={validation.date} value={projectState.date} type="date" placeholder="Ange ett datum" name="date" onChange={handleInputs} /> : null}
                  </div>
                </div>

                <div className="field">
                  <label className="label">Kund <span className="has-text-danger">*</span></label>
                  <div className="control">
                    {props.modalTitle === 'Redigera Projekt' ? <input className={validation.client} value={editState.client} type="text" placeholder="Ange kundens namn" name="client" onChange={handleInputs} /> : null}
                    {props.modalTitle === 'Skapa Projekt' ? <input className={validation.client} value={projectState.client} type="text" placeholder="Ange kundens namn" name="client" onChange={handleInputs} /> : null}
                  </div>
                </div>

                <div className="field">
                  <label className="label">Cesium JSON <span className="has-text-danger">*</span></label>
                  <div className="control has-icons-left">
                    {props.modalTitle === 'Redigera Projekt' ? <input className={validation.url} value={editState.url} type="text" placeholder="Skriv in länken till Cesiumfilerna" name="url" onChange={handleInputs} /> : null}
                    {props.modalTitle === 'Skapa Projekt' ? <input className={validation.url} value={projectState.url} type="text" placeholder="Skriv in länken till Cesiumfilerna" name="url" onChange={handleInputs} /> : null}
                    <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={faGlobe} />
                    </span>
                  </div>
                </div>

                <div className="is-divider mb-4" data-content="Säkerhet"></div>

                <div className="field">
                  <label className="label">Lösenordskyddat projekt</label>
                  <div className="control has-icons-left">
                    {props.modalTitle === 'Redigera Projekt' ? <input className="input" value={editState.password} type="text" placeholder="Om projektet ska vara låst, ange lösenord här" name="password" onChange={handleInputs} /> : null}
                    {props.modalTitle === 'Skapa Projekt' ? <input className="input" value={projectState.password} type="text" placeholder="Om projektet ska vara låst, ange lösenord här" name="password" onChange={handleInputs} /> : null}
                    <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={faLock} />
                    </span>
                  </div>
                </div>
              </section>
              <footer className="modal-card-foot">
                {props.modalType ? <button className="button is-success" disabled={validationDisable} onClick={addNewProject}>Lägg till</button> : <button className="button is-success" onClick={editProject}>Spara</button>}
                <button className="button" onClick={props.abort}>Avbryt</button>
              </footer>
            </form>
          </div>
        </div>
      }
    </>
  );
};

Modal.propTypes = {
  modalStatus: PropTypes.string,
  close: PropTypes.func,
  abort: PropTypes.func,
  modalTitle: PropTypes.string,
  modalType: PropTypes.bool,
  edit: PropTypes.func,
  removeOrAddEdit: PropTypes.bool,
  delete: PropTypes.func,
};

export default Modal;
