import React from 'react';

const Footer = () => {
  return (
    <footer className="footer mt-6">
      <div className="content has-text-centered">
        <p>
          Copyright © 2020, Swescan AB. Utvecklat av <a href="https://ecsolutions.se">EC Solutions</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
