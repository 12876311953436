import axios from 'axios';
import { useHistory } from 'react-router';

export default {

  /**
   * Bind the authentication middleware.
   * Will listen to response code 401 and redirect the user to the login screen.
   */
  bind_authentication_middleware: () => {
    const history = useHistory();

    const router = path => {
      history.push(path);
    };

    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
        router('/login');
      }
      return error;
    });
  },

};
