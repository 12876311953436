// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../public/img/background.jpg");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login__AbBMM{width:100vw;height:100vh;display:flex;align-items:center;flex-direction:column;justify-content:center;background-size:cover;background-image:linear-gradient(#fff, rgba(255,255,255,0.8)),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.login__logo__2nM3E{height:auto;width:18.75rem;margin-bottom:2.5rem}.form-wrapper__GFiqw{width:300px;margin-bottom:5px}\n", "",{"version":3,"sources":["Login.module.scss"],"names":[],"mappings":"AAAA,cAAO,WAAW,CAAC,YAAY,CAAC,YAAY,CAAC,kBAAkB,CAAC,qBAAqB,CAAC,sBAAsB,CAAC,qBAAqB,CAAC,qGAA0G,CAAC,oBAAa,WAAW,CAAC,cAAc,CAAC,oBAAoB,CAAC,qBAAc,WAAW,CAAC,iBAAiB","file":"Login.module.scss","sourcesContent":[".login{width:100vw;height:100vh;display:flex;align-items:center;flex-direction:column;justify-content:center;background-size:cover;background-image:linear-gradient(#fff, rgba(255,255,255,0.8)),url(\"../../../../public/img/background.jpg\")}.login__logo{height:auto;width:18.75rem;margin-bottom:2.5rem}.form-wrapper{width:300px;margin-bottom:5px}\n"]}]);
// Exports
exports.locals = {
	"login": "login__AbBMM",
	"login__logo": "login__logo__2nM3E",
	"form-wrapper": "form-wrapper__GFiqw"
};
module.exports = exports;
