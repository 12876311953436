import React from 'react';
import { ProjectContext } from '../../views/App/App.jsx';
import { ToolTypes } from '../../utils/tools';
import { Cartesian3, ScreenSpaceEventType, Cartographic } from 'cesium';
import { CesiumContext, ScreenSpaceEventHandler, ScreenSpaceEvent } from 'resium';
import { setActiveTool, addPoint, addPolyline, addLabel, removeAll } from '../../actions/actions';
import {
  createPoint,
  createPolyline,
  createLabel,
  updatePointData,
  calculateLabelOffset,
  getAngleGroundHypotenuse,
  getAngleVerticalHypotenuse,
  getHorizontalDistanceString,
  getDistanceString,
  getVerticalDistanceString,
  getMidpoint,
  getHorizontalDistanceNumber,
  getDistanceNumber,
  getVerticalDistanceNumber,
} from '../../utils/utils.jsx';

/* ---[ Presentation ]--- */
import Tippy from '@tippyjs/react';
import Icon from '../../../svg/tool-measure.svg';
import classNames from 'classnames/bind';
import styles from './Tools.module.scss';
const style = classNames.bind(styles);

/* ---[ Declarations ]--- */
const TOOL_NAME = ToolTypes.MEASURE_TOOL;

/**
 * Measure Tool
 * @returns {*}
 * @constructor
 */
const MeasureTool = () => {
  const project_context = React.useContext(ProjectContext);
  const cesium_context = React.useContext(CesiumContext);

  const [state, setState] = React.useState({
    positions: [],
    click_count: 0,
  });

  const is_active = project_context.state.active_tool === TOOL_NAME;

  /**
   * Enable this tool.
   */
  const enable = () => {
    resetState();
    project_context.dispatch(removeAll());
    project_context.dispatch(setActiveTool(TOOL_NAME));
  };

  /**
   * Disable this tool.
   */
  const disable = () => {
    resetState();
    project_context.dispatch(removeAll());
    project_context.dispatch(setActiveTool(null));
  };

  /**
   * Toggle the tool active status and update state.
   */
  const toggle = () => (is_active ? disable() : enable());

  /**
   * Reset the state back to initial mode.
   */
  const resetState = () => setState({
    positions: [],
    click_count: 0,
  });

  /**
   * Get polyline data from on geographical coordinates.
   * @param geo_positions
   * @returns {[{positions: [Cartesian3.fromRadians, Cartesian3.fromRadians], type: string}, {positions: [Cartesian3.fromRadians, Cartesian3.fromRadians], type: string}, {positions: [Cartesian3.fromRadians, Cartesian3.fromRadians], type: string}]}
   */
  const generatePolylineData = geo_positions => {
    return [
      {
        type: 'Color',
        positions: [
          new Cartesian3.fromRadians(geo_positions[0].longitude, geo_positions[0].latitude, geo_positions[0].height),
          new Cartesian3.fromRadians(geo_positions[1].longitude, geo_positions[1].latitude, geo_positions[1].height),
        ],
      },
      {
        type: 'Color',
        positions: [
          new Cartesian3.fromRadians(geo_positions[1].longitude, geo_positions[1].latitude, geo_positions[1].height),
          new Cartesian3.fromRadians(geo_positions[1].longitude, geo_positions[1].latitude, geo_positions[0].height),
        ],
      },
      {
        type: 'Color',
        positions: [
          new Cartesian3.fromRadians(geo_positions[0].longitude, geo_positions[0].latitude, geo_positions[0].height),
          new Cartesian3.fromRadians(geo_positions[1].longitude, geo_positions[1].latitude, geo_positions[0].height),
        ],
      },
    ];
  };

  /**
   * Generate necessary data to create labels on three polylines.
   * @param point_1
   * @param point_2
   * @param geo_positions
   * @returns {[{text: string, position: Cartesian3.fromRadians}, {text: string, position: Cartesian3.fromRadians}, {text: string, position: Cartesian3.fromRadians}]}
   */
  const generateLabelData = (point_1, point_2, geo_positions) => {
    point_1 = updatePointData(point_1);
    point_2 = updatePointData(point_2);

    const height = calculateLabelOffset(geo_positions[0], geo_positions[1]);

    const groundToHypotenuse = getAngleGroundHypotenuse(getHorizontalDistanceNumber(point_1, point_2), getDistanceNumber(point_1, point_2, geo_positions[0], geo_positions[1]));
    const verticalHypotenuse = getAngleVerticalHypotenuse(getDistanceNumber(point_1, point_2, geo_positions[0], geo_positions[1]), getVerticalDistanceNumber(geo_positions[0], geo_positions[1]));

    return [
      {
        text: getHorizontalDistanceString(point_1, point_2),
        position: getMidpoint(point_1, point_2, geo_positions[0].height),
      },
      {
        text: '(' + groundToHypotenuse + '°)',
        position: point_1,
      },
      {
        text: getDistanceString(point_1, point_2, geo_positions[0], geo_positions[1]),
        position: getMidpoint(point_1, point_2, height),
      },
      {
        text: '(' + verticalHypotenuse + '°)',
        position: point_2,
      },
      {
        text: getVerticalDistanceString(geo_positions[0], geo_positions[1]),
        position: getMidpoint(point_2, point_2, height),
      },
    ];
  };

  /**
   * Handle left mouse click.
   * @param cartesian2
   */
  const handleLeftClick = cartesian2 => {
    const click_position = cesium_context.viewer.scene.pickPosition(cartesian2.position);
    const position = new Cartesian3(click_position.x, click_position.y, click_position.z);

    if (state.click_count === 0) {
      const point = createPoint(position);
      project_context.dispatch(addPoint(point));

      setState({
        positions: [...state.positions, position],
        click_count: state.click_count += 1,
      });
    } else if (state.click_count === 1) {
      const point = createPoint(position);
      project_context.dispatch(addPoint(point));

      const geo_positions = [
        Cartographic.fromCartesian(state.positions[0]),
        Cartographic.fromCartesian(position),
      ];

      const polyline_data = generatePolylineData(geo_positions);
      polyline_data.map(data => {
        const polyline = createPolyline(data.type, data.positions);
        project_context.dispatch(addPolyline(polyline));
      });

      const label_data = generateLabelData(state.positions[0], position, geo_positions);
      label_data.map(data => {
        const label = createLabel(data.text, data.position);
        project_context.dispatch(addLabel(label));
      });

      setState({
        positions: [...state.positions, position],
        click_count: state.click_count += 1,
      });
    } else if (state.click_count === 2) {
      project_context.dispatch(removeAll());
      resetState();
    }
  };

  const classes = style('tools__button', {
    'tools__button--active': is_active,
  });

  return (
    <>
      <Tippy content="Measure Tool" placement="right" offset={[0, 16]}>
        <button className={classes} onClick={toggle}>
          <Icon className={style('tools__icon')} />
        </button>
      </Tippy>
      {
        is_active &&
        <ScreenSpaceEventHandler>
          <ScreenSpaceEvent action={handleLeftClick} type={ScreenSpaceEventType.LEFT_CLICK} />
        </ScreenSpaceEventHandler>
      }
    </>
  );
};

export default MeasureTool;
