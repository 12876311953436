// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".logo__4GUnV{width:112px;height:18px}\n", "",{"version":3,"sources":["Navbar.module.scss"],"names":[],"mappings":"AAAA,aAAM,WAAW,CAAC,WAAW","file":"Navbar.module.scss","sourcesContent":[".logo{width:112px;height:18px}\n"]}]);
// Exports
exports.locals = {
	"logo": "logo__4GUnV"
};
module.exports = exports;
