import React from 'react';
import PropTypes from 'prop-types';

const InputFilter = (props) => {
  const getSearchValue = e => props.callback(e.target.value);

  return (
    <input className="input" placeholder={props.placeholder} onChange={getSearchValue} value={props.searchTerm} />
  );
};

InputFilter.propTypes = {
  callback: PropTypes.any,
  searchTerm: PropTypes.any,
  placeholder: PropTypes.string,
};

export default InputFilter;
