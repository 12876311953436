import { ActionTypes } from '../actions/actions';

export const app_reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_TOOL:
      return {
        ...state,
        active_tool: action.payload,
      };

    case ActionTypes.ADD_POINT:
      return {
        ...state,
        points: [...state.points, action.payload],
      };
    case ActionTypes.REMOVE_POINT:
      return {
        ...state,
        points: state.points.filter(p => p.key !== action.payload),
      };
    case ActionTypes.REMOVE_POINTS:
      return {
        ...state,
        points: [],
      };

    case ActionTypes.ADD_LABEL:
      return {
        ...state,
        labels: [...state.labels, action.payload],
      };
    case ActionTypes.REMOVE_LABEL:
      return {
        ...state,
        labels: state.labels.filter(p => p.key !== action.payload),
      };
    case ActionTypes.REMOVE_LABELS:
      return {
        ...state,
        labels: [],
      };

    case ActionTypes.ADD_POLYLINE:
      return {
        ...state,
        polylines: [...state.polylines, action.payload],
      };
    case ActionTypes.REMOVE_POLYLINE:
      return {
        ...state,
        polylines: state.polylines.filter(p => p.key !== action.payload),
      };
    case ActionTypes.REMOVE_POLYLINES:
      return {
        ...state,
        polylines: [],
      };

    case ActionTypes.REMOVE_ALL:
      return {
        ...state,
        points: [],
        labels: [],
        polylines: [],
      };

    case ActionTypes.UPDATE_PROJECT:
      return {
        ...state,
        current_project: action.value,
      };

    case ActionTypes.STATE_IS_UPDATING:
      return {
        ...state,
        state_updating: action.value,
      };

    case ActionTypes.UPDATE_PROJECT_PRIVACY:
      return {
        ...state,
        project_private: action.value,
      };

    default:
      return state;
  }
};
