import 'core-js';
import React from 'react';
import RenderDOM from 'react-dom';
import 'tippy.js/dist/tippy.css';
import 'regenerator-runtime/runtime.js';
import App from './js/views/App/App.jsx';

/* ---[ Presentation ]--- */
import './css/reset.css';
import './css/general.scss';

// @note: Hacky "solution" to "fix" polyline depth test.
const old_update = window.Cesium.PolylineCollection.prototype.update;
window.Cesium.PolylineCollection.prototype.update = function(frame_state) {
  const old_morph_time = frame_state.morphTime;
  frame_state.morphTime = 0.0;
  old_update.call(this, frame_state);
  frame_state.morphTime = old_morph_time;
};

RenderDOM.render(<App />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
