import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { setCurrentProject, updateState } from '../../actions/actions';
import AxiosInterceptor from '../../utils/interceptor';
import { ProjectContext } from '../App/App.jsx';

/* ---[ Presentation ]--- */
import 'bulma/css/bulma.css';

/* ---[ Components ]--- */
import ReactPaginate from 'react-paginate';
import InputFilter from '../../components/Input/Input';
import ProjectInfo from '../../components/ProjectInfo/ProjectInfo.jsx';
import ErroText from '../../components/ErrorText/ErrorText.jsx';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Modal from '../../components/Modal/Modal.jsx';

/* ---[ Iconography ]--- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import Arrow from '../../../svg/admin-arrow.svg';

/* ---[ Presentation ]--- */
import classNames from 'classnames/bind';
import styles from './Projects.module.scss';
const style = classNames.bind(styles);

const Projects = () => {
  const project_context = React.useContext(ProjectContext);
  const [projects, setProjects] = useState([]);
  const [searchError, setSearchError] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [update, setUpdate] = useState(false);
  const [modalStatus, setModalStatus] = useState('modal is-close');
  const [currentModalTitle, setCurrentModalTitle] = useState('');
  const [newProjectModal, setNewProjectModal] = useState(null);
  const [paginationState, setPaginationState] = useState({ offset: 0, data: [], elements: [], perPage: 10, currentPage: 0 });
  const [whatModal, setWhatModal] = useState(null);
  const [activateDropdown, setActivateDropdown] = useState('dropdown');
  const [allClients, setAllClients] = useState([]);
  const [countPages, setCountPages] = useState(false);
  const [updateOffset, setUpdateOffset] = useState(false);
  const [showElements, setShowElements] = useState(false);
  const [showPag, setShowPag] = useState(true);
  const [dropdownSpanName, setDropdownSpanName] = useState('Filtrera på kund');
  const [currentDropdownClient, setCurrentDropdownClient] = useState('');
  const [resetClientSearch, setResetClientSearch] = useState(false);

  const [titleSortDescending, setTitleSortDescending] = useState(true);
  const [clientSortDescending, setClientSortDescending] = useState(true);
  const [descriptionSortDescending, setDescriptionSortDescending] = useState(true);
  const [createdSortDescending, setCreatedSortDescending] = useState(true);
  const [dateSortDescending, setDateSortDescending] = useState(true);

  AxiosInterceptor.bind_authentication_middleware();
  const accessString = localStorage.getItem('token');
  const node = useRef();

  /**
   * Fetches all projects from the DB.
   */
  useEffect(() => {
    const getData = async() => {
      await axios.get('/api/projects', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: `JWT ${accessString}`,
        },
      }).then(response => {
        const projects = response.data.map(x => {
          return x.password.length > 0 ? { ...x, styling: 'has-text-danger', status: 'Lösenordsskyddad' } : { ...x, styling: 'has-text-success', status: 'Publik länk' };
        });
        setProjects(projects);
        setPaginationState({ ...paginationState, data: projects, pageCount: Math.ceil(projects.length / paginationState.perPage) });
      });
    };
    getData();
  }, []);

  /**
   * Adds event listeners to check if you click
   * on the dropdown or outside of it,
   * the addeEventListener is when component mounts and
   * the removeEventListener is when the component unmounts.
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleOuterClick);

    return () => {
      document.removeEventListener('mousedown', handleOuterClick);
    };
  }, []);

  /**
   * Re renders projects when sorting projects.
   */
  useEffect(() => {
    projectsForCurrentPage();
  }, [titleSortDescending, clientSortDescending, dateSortDescending, createdSortDescending, descriptionSortDescending]);

  /**
   * If you update something using modal
   * it sets it to the context state and if that returns true
   * it updates the local state.
   */
  useEffect(() => {
    if (project_context.state.state_updating) {
      setUpdate(true);
    }
  }, [project_context.state.state_updating]);

  /**
   * Fetches all specific Clients (not duplicates).
   */
  useEffect(() => {
    const getClientData = async() => {
      await axios.get('/api/projects/clients', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: `JWT ${accessString}`,
        },
      }).then(response => {
        setAllClients(response.data);
      });
    };
    getClientData();
  }, []);

  /**
   * Updates client list.
   */
  useEffect(() => {
    if (update) {
      const getNewClientData = async() => {
        await axios.get('/api/projects/clients', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            Authorization: `JWT ${accessString}`,
          },
        }).then(response => {
          setAllClients(response.data);
        });
      };
      getNewClientData();
    }
  }, [update]);

  /**
   * If the state updates there could be more pages depending on
   * the amount of projects, this checks how many pages we got and sets the
   * amount of projects accordingly, per page.
   */
  useEffect(() => {
    if (paginationState.currentPage > 0 || paginationState.currentPage === 0) {
      projectsForCurrentPage();
    };
  }, [paginationState.currentPage]);

  /**
   * Checks the updated data and sets that to the elements array
   * so the new data can be shown right away.
   */
  useEffect(() => {
    if (paginationState.data.length !== paginationState.elements.length) {
      projectsForCurrentPage();
    }
  }, [paginationState.data.length]);

  /**
   * Takes in the paginationstate and maps Projectinfo component
   * into elements with the current data available.
   */
  const projectsForCurrentPage = () => {
    const currentProjects = paginationState.data.slice(paginationState.offset, paginationState.offset + paginationState.perPage)
      .map((projectInfo) => renderProject(projectInfo));
    setPaginationState({ ...paginationState, elements: currentProjects });
  };

  /**
   * Updates the offset depending on
   * what page you are currently on.
   */
  useEffect(() => {
    if (updateOffset) {
      const offset = paginationState.currentPage * paginationState.perPage;
      setPaginationState({ ...paginationState, offset: offset });
      setShowElements(true);
      setUpdateOffset(false);
    }
  }, [updateOffset]);

  /**
   * Runs projectsForCurrentPage with
   * the correct state so it updates correctly.
   */
  useEffect(() => {
    if (showElements) {
      projectsForCurrentPage();
      setShowElements(false);
    }
  }, [showElements]);

  /**
   * When a new project it made it checks
   * when its time for a new pageCount and adds that to the state.
   */
  useEffect(() => {
    if (countPages) {
      setPaginationState({ ...paginationState, pageCount: Math.ceil(projects.length / paginationState.perPage) });
      if (paginationState.elements.length === 0) {
        setPaginationState({ ...paginationState, currentPage: paginationState.currentPage - 1, pageCount: paginationState.pageCount - 1 });
        setUpdateOffset(true);
      }
      setCountPages(false);
    }
  }, [countPages, paginationState.elements.length]);

  /**
   * Checks if an project is updated and fetches the new ones.
   * For example if a project is deleted it fetches the new ones
   * and this useEffect acts like a componentDidUpdate so the user
   * will see the current projects instantly.
   */
  useEffect(() => {
    if (update) {
      const getNewData = async() => {
        await axios.get('/api/projects', {
          headers: {
            Authorization: `JWT ${accessString}`,
          },
        }).then(response => {
          const updatedProjects = response.data.map(x => {
            return x.password.length > 0 ? { ...x, styling: 'has-text-danger', status: 'Lösenordsskyddad' } : { ...x, styling: 'has-text-success', status: 'Publik länk' };
          });
          setProjects(updatedProjects);
          setPaginationState({ ...paginationState, data: updatedProjects });
          setUpdate(false);
          project_context.dispatch(updateState(false));
          setModalStatus('modal is-close');
          projectsForCurrentPage();
          setCountPages(true);
        }).catch((error) => {
          console.log(error);
        });
      };
      projectsForCurrentPage();
      getNewData();
    }
  }, [update, paginationState.data]);

  /**
   * Sets the correct title and type for the modal
   * so you can create a new project.
   */
  const addProject = () => {
    setWhatModal(false);
    setCurrentModalTitle('Skapa Projekt');
    setModalStatus('modal is-active');
    setNewProjectModal(true);
  };

  /**
   * Dispatches an action to the state
   * so the modal can reach the targeted project.
   * @param {*} event
   */
  const setProjectToStateForRemoval = (event) => {
    const targetName = event.currentTarget.getAttribute('name');
    const targetFilter = projects.filter(target => target.id === targetName);
    project_context.dispatch(setCurrentProject(targetFilter[0]));
    setWhatModal(true);
    setModalStatus('modal is-active');
    setCurrentModalTitle('');
  };

  /**
   * Removes a project.
   * @param {*} e
   */
  const removeProject = (e) => {
    e.preventDefault();
    axios.delete(`/api/projects/${project_context.state.current_project.id}`, {
      headers: {
        Authorization: `JWT ${accessString}`,
      },
    }).then(response => {
      if (response.status === 200) {
        if (searchResult !== null) {
          const removeDeletedProject = searchResult.filter(project => project.key !== project_context.state.current_project.id);
          const searchAfterDeletion = projects.filter(project1 => {
            return removeDeletedProject.some(project2 => {
              return project1.id === project2.key;
            });
          });
          if (searchAfterDeletion.length === 0) {
            setSearchError(true);
          }
          setSearchResult(renderAllProjects(searchAfterDeletion));
        }
        setDropdownSpanName('Filtrera på kund');
        setUpdate(true);
        closeModal();
      }
    }).catch(error => {
      return error;
    });
  };

  /**
   * Sets the targeted project into our context state,
   * This so our modal can get the information needed to make a PUT request.
   * @param {*} event
   */
  const handleEditProject = (event) => {
    setWhatModal(false);
    setNewProjectModal(false);
    setCurrentModalTitle('Redigera Projekt');
    setModalStatus('modal is-active');
    const editTarget = event.currentTarget.getAttribute('name');
    const targetedProject = projects.filter(target => target.id === editTarget);
    project_context.dispatch(setCurrentProject(targetedProject[0]));
  };

  /**
   * Filters projects by client, title or description.
   * @param {*} searchTerm
   */
  const searchProject = searchTerm => {
    const clientSearch = projects.filter(project => {
      return project.client.toLowerCase().includes(searchTerm.toLowerCase());
    });
    const titleSearch = projects.filter(project => {
      return project.title.toLowerCase().includes(searchTerm.toLowerCase());
    });
    const descSearch = projects.filter(project => {
      return project.description.toLowerCase().includes(searchTerm.toLowerCase());
    });
    if (searchTerm === '') {
      setSearchError(false);
      setSearchResult(null);
      setShowPag(true);
    } else {
      if (clientSearch.length <= 0 && titleSearch.length <= 0 && descSearch.length <= 0) {
        setSearchError(true);
        setSearchResult(renderAllProjects([]));
      }
      if (clientSearch.length > 0) {
        setSearchError(false);
        setSearchResult(renderAllProjects(clientSearch));
        setShowPag(false);
      }
      if (titleSearch.length > 0) {
        setSearchError(false);
        setSearchResult(renderAllProjects(titleSearch));
        setShowPag(false);
      }
      if (descSearch.length > 0) {
        setSearchError(false);
        setSearchResult(renderAllProjects(descSearch));
        setShowPag(false);
      }
      if (titleSearch.length > 0 && clientSearch.length > 0 && descSearch.length > 0) {
        const combinedSearch = [titleSearch, clientSearch, descSearch];
        const combineAll = combinedSearch.reduce((a, b) => [...a, ...b], []);

        const removeDuplicates = combineAll.filter((project, index) => {
          const _project = JSON.stringify(project);
          return index === combineAll.findIndex(obj => {
            return JSON.stringify(obj) === _project;
          });
        });

        setSearchError(false);
        setSearchResult(renderAllProjects(removeDuplicates));
        setShowPag(false);
      }
      if (titleSearch.length > 0 && clientSearch.length > 0) {
        const combinedSearch = [...titleSearch, ...clientSearch];
        const removeDuplicates = combinedSearch.filter((project, index) => {
          const _project = JSON.stringify(project);
          return index === combinedSearch.findIndex(obj => {
            return JSON.stringify(obj) === _project;
          });
        });

        setSearchError(false);
        setSearchResult(renderAllProjects(removeDuplicates));
        setShowPag(false);
      }
      if (titleSearch.length > 0 && descSearch.length > 0) {
        const combinedSearch = [...titleSearch, ...descSearch];
        const removeDuplicates = combinedSearch.filter((project, index) => {
          const _project = JSON.stringify(project);
          return index === combinedSearch.findIndex(obj => {
            return JSON.stringify(obj) === _project;
          });
        });

        setSearchError(false);
        setSearchResult(renderAllProjects(removeDuplicates));
        setShowPag(false);
      }
      if (clientSearch.length > 0 && descSearch.length > 0) {
        const combinedSearch = [...descSearch, ...clientSearch];
        const removeDuplicates = combinedSearch.filter((project, index) => {
          const _project = JSON.stringify(project);
          return index === combinedSearch.findIndex(obj => {
            return JSON.stringify(obj) === _project;
          });
        });

        setSearchError(false);
        setSearchResult(renderAllProjects(removeDuplicates));
        setShowPag(false);
      }
    };
  };

  /**
   * Information about one specific project.
   * @param {*} projects
   */
  const renderProject = (projects) => {
    return (
      <ProjectInfo
        title={projects.title}
        key={projects.id}
        client={projects.client}
        url={projects.cesium_url}
        description={projects.description}
        date={projects.date}
        timestamp={projects.timestamp}
        id={projects.id}
        name={projects.id}
        remove={setProjectToStateForRemoval}
        public={projects.status}
        protected={projects.styling}
        editProject={handleEditProject}
      />
    );
  };

  /**
   * Simply just closes the modal.
   */
  const closeModal = (e) => {
    setModalStatus('modal is-close');
    e.preventDefault();
  };

  /**
   * Opens the dropdown component.
   * And closes when u click outside of it.
   */
  const handleOuterClick = (event) => {
    if (node.current.contains(event.target)) {
      setActivateDropdown('dropdown is-active');
      return;
    }
    setActivateDropdown('dropdown');
  };

  /**
   * If you click on an item inside the dropdown
   * it sets the class to dropdown so it closes.
   */
  const closeDropdown = () => {
    setActivateDropdown('dropdown');
  };

  /**
   * When you select a client it updates the
   * searchresult state so you only get the projects from
   * the client you selected.
   * @param {*} event
   */
  const selectClient = (event) => {
    const name = event.target.getAttribute('name');
    const chosenClient = allClients.filter(clientName => clientName === name);
    if (chosenClient.length > 0) {
      const targetedClient = chosenClient[0];
      if (currentDropdownClient === name) {
        setResetClientSearch(true);
      };
      setCurrentDropdownClient(targetedClient);
      const checkForMatch = projects.filter(project => project.client === targetedClient);
      document.getElementsByName(name)[0].classList.add('is-active');
      const elements = document.getElementsByClassName('dropdown-item is-active');
      setDropdownSpanName(name);
      if (elements.length > 1) {
        for (const element of elements) {
          if (element.name !== name) {
            element.classList.remove('is-active');
          };
        }
      }
      setShowPag(false);
      setSearchError(false);
      setSearchResult(renderAllProjects(checkForMatch));
    };
  };

  useEffect(() => {
    if (resetClientSearch) {
      resetSelectedClient();
    };
  }, [resetClientSearch]);

  /**
   * If the user selected a client but
   * wants to see all clients again this just
   * resets it so all clients are shown.
   */
  const resetSelectedClient = () => {
    setSearchResult(null);
    setSearchError(false);
    setShowPag(true);
    setResetClientSearch(false);
    setDropdownSpanName('Filtrera på kund');
    const activeElement = document.getElementsByClassName('dropdown-item is-active');
    setCurrentDropdownClient('');
    if (activeElement.length > 0) {
      activeElement.item(0).classList.remove('is-active');
    }
  };

  /**
   * Renders the clients in the dropdown component.
   */
  const renderClients = allClients.map(client => <a key={client} name={client} className='dropdown-item'>
    <div name={client}>{client}</div>
  </a>);

  /**
   * Renders out all projects.
   * @param {*} currentProjects
   */
  const renderAllProjects = (currentProjects = projects) =>
    currentProjects ? currentProjects.map((projectInfo) => renderProject(projectInfo)) : null;

  /**
   * Updates the currentpage you click on.
   * @param {*} data
   */
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * paginationState.perPage;
    setPaginationState({ ...paginationState, currentPage: selectedPage, offset: offset });
  };

  /**
   * Finds all projects that's in the searchResult.
   */
  const getProjectsInSearch = () => {
    const newProjectsArray = [];

    for (let i = 0; i < searchResult.length; i++) {
      const item = projects.find(item => item.id === searchResult[i].props.id);
      newProjectsArray.push(item);
    }
    return newProjectsArray;
  };

  /**
   * Sorts projects based on Project title.
   * @param {*} event
   */
  const projectsSortOnClick = (event) => {
    if (titleSortDescending) {
      if (searchResult) {
        const sortedProjects = getProjectsInSearch().sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1));
        setSearchResult(renderAllProjects(sortedProjects));
      } else {
        const sortedProjects = [...projects].sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1));
        setPaginationState({ ...paginationState, data: sortedProjects });
        setProjects(sortedProjects);
      }
    } else {
      if (searchResult) {
        const sortedProjects = getProjectsInSearch().sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
        setSearchResult(sortedProjects);
        setSearchResult(renderAllProjects(sortedProjects));
      } else {
        const sortedProjects = [...projects].sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
        setPaginationState({ ...paginationState, data: sortedProjects });
        setProjects(sortedProjects);
      }
    }
    setTitleSortDescending(!titleSortDescending);
  };

  /**
   * Sorts projects based on client name.
   * @param {*} event
   */
  const clientsSortOnClick = (event) => {
    if (clientSortDescending) {
      const sortedProjects = [...projects].sort((a, b) => (a.client.toLowerCase() > b.client.toLowerCase() ? -1 : 1));
      setPaginationState({ ...paginationState, data: sortedProjects });
      setProjects(sortedProjects);
    } else {
      const sortedProjects = [...projects].sort((a, b) => (a.client.toLowerCase() > b.client.toLowerCase() ? 1 : -1));
      setPaginationState({ ...paginationState, data: sortedProjects });
      setProjects(sortedProjects);
    }
    setClientSortDescending(!clientSortDescending);
  };

  /**
   * Sorts projects based on description.
   * @param {*} event
   */
  const descriptionSortOnClick = (event) => {
    if (descriptionSortDescending) {
      if (searchResult) {
        const sortedProjects = getProjectsInSearch().sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase() ? -1 : 1));
        setSearchResult(sortedProjects);
        setSearchResult(renderAllProjects(sortedProjects));
      } else {
        const sortedProjects = [...projects].sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase() ? -1 : 1));
        setPaginationState({ ...paginationState, data: sortedProjects });
        setProjects(sortedProjects);
      }
    } else {
      if (searchResult) {
        const sortedProjects = getProjectsInSearch().sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1));
        setSearchResult(sortedProjects);
        setSearchResult(renderAllProjects(sortedProjects));
      } else {
        const sortedProjects = [...projects].sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1));
        setPaginationState({ ...paginationState, data: sortedProjects });
        setProjects(sortedProjects);
      }
    }
    setDescriptionSortDescending(!descriptionSortDescending);
  };

  /**
   * Sorts projects based on date.
   * @param {*} event
   */
  const dateSortOnClick = (event) => {
    if (dateSortDescending) {
      if (searchResult) {
        const sortedProjects = getProjectsInSearch().sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });
        setSearchResult(renderAllProjects(sortedProjects));
      } else {
        const sortedProjects = [...projects].sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });
        setPaginationState({ ...paginationState, data: sortedProjects });
        setProjects(sortedProjects);
      }
    } else {
      if (searchResult) {
        const sortedProjects = getProjectsInSearch().sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        }).reverse();
        setSearchResult(renderAllProjects(sortedProjects));
      } else {
        const sortedProjects = [...projects].sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        }).reverse();
        setPaginationState({ ...paginationState, data: sortedProjects });
        setProjects(sortedProjects);
      }
    }
    setDateSortDescending(!dateSortDescending);
  };

  /**
   * Sorts projects based on creation date.
   * @param {*} event
   */
  const createdSortOnClick = (event) => {
    if (createdSortDescending) {
      if (searchResult) {
        const sortedProjects = getProjectsInSearch().sort((a, b) => {
          return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
        });
        setSearchResult(renderAllProjects(sortedProjects));
      } else {
        const sortedProjects = [...projects].sort((a, b) => {
          return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
        });
        setPaginationState({ ...paginationState, data: sortedProjects });
        setProjects(sortedProjects);
      }
    } else {
      if (searchResult) {
        const sortedProjects = getProjectsInSearch().sort((a, b) => {
          return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
        }).reverse();
        setSearchResult(renderAllProjects(sortedProjects));
      } else {
        const sortedProjects = [...projects].sort((a, b) => {
          return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
        }).reverse();
        setPaginationState({ ...paginationState, data: sortedProjects });
        setProjects(sortedProjects);
      }
    }
    setCreatedSortDescending(!createdSortDescending);
  };

  return (
    <div>
      <Navbar />
      <Header title="Projektöversikt" text="Här kan du se och redigera alla aktiva projekt samt skapa nya." />
      <div className="table-container projects-table" style={{ minHeight: '38vh' }}>
        <div className="container">
          <div className="filters columns">
            <div className="column">
              <div className="field">
                <div className="control">
                  <InputFilter placeholder='Sök projekt, t.ex kund' callback={searchProject} />
                </div>
              </div>
            </div>
            <div className="column is-narrow" name="dropdown" ref={node}>
              <div className={activateDropdown}>
                <div className="dropdown-trigger">
                  <button className="button" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span>{dropdownSpanName}</span>
                    <span className="icon is-small">
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                  </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu" onClick={closeDropdown}>
                  <div className="dropdown-content" style={{ overflow: 'auto', maxHeight: '19rem' }} onClick={selectClient}>
                    <a className="dropdown-item" onClick={resetSelectedClient}>
                      Alla företag
                    </a>
                    <hr className="dropdown-divider" />
                    {renderClients}
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-narrow">
              <button className="button is-info" onClick={addProject}>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faPlus} />
                </span>
                <span>Nytt projekt</span>
              </button>
            </div>
          </div>
          <table className="table is-fullwidth is-striped">
            <thead>
              <tr>
                <th className={style('table__head')} onClick={projectsSortOnClick}>
                  <div className={style('table__container')}>
                    Projekt
                    <Arrow className={style('table__arrow', titleSortDescending ? '' : 'table__arrow--down')} />
                  </div>
                </th>
                <th className={style('table__head')} onClick={clientsSortOnClick}>
                  <div className={style('table__container')}>
                    Kund
                    <Arrow className={style('table__arrow', clientSortDescending ? '' : 'table__arrow--down')} />
                  </div>
                </th>
                <th className={style('table__head')} onClick={descriptionSortOnClick}>
                  <div className={style('table__container')}>
                    Beskrivning
                    <Arrow className={style('table__arrow', descriptionSortDescending ? '' : 'table__arrow--down')} />
                  </div>
                </th>
                <th className={style('table__head')} onClick={dateSortOnClick}>
                  <div className={style('table__container')}>
                    Datum
                    <Arrow className={style('table__arrow', dateSortDescending ? '' : 'table__arrow--down')} />
                  </div>
                </th>
                <th className={style('table__head')} onClick={createdSortOnClick}>
                  <div className={style('table__container')}>
                    Publiceringsdatum
                    <Arrow className={style('table__arrow', createdSortDescending ? '' : 'table__arrow--down')} />
                  </div>
                </th>
                <th>Skyddad</th>
                <th className="has-text-right">Åtgärder</th>
              </tr>
            </thead>
            <tbody id="projectsContainer">
              {searchError ? <ErroText /> : null}
              {searchResult || paginationState.elements || renderAllProjects()}
            </tbody>
          </table>
          {paginationState.pageCount > 1 && showPag ? <ReactPaginate
            previousLabel={<div className="pagination-previous" id="prevButton">Föregående</div>}
            nextLabel={<div className="pagination-next" id="nextButton">Nästa</div>}
            breakLabel={<span className="pagination-ellipsis">&hellip;</span>}
            pageCount={paginationState.pageCount}
            onPageChange={handlePageClick}
            forcePage={paginationState.currentPage}
            containerClassName={style('pagination-wrapper')}
            previousLinkClassName={'previous_page'}
            nextLinkClassName={'next_page'}
            disabledClassName={'disabled'}
            pageLinkClassName={'pagination-link'}
            activeLinkClassName={'is-current'}
          /> : null}
        </div>
      </div>
      <Footer />
      <Modal
        modalStatus={modalStatus}
        close={closeModal}
        abort={closeModal}
        modalTitle={currentModalTitle}
        modalType={newProjectModal}
        edit={handleEditProject}
        removeOrAddEdit={whatModal}
        delete={removeProject}
      />
    </div>
  );
};

Projects.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default Projects;
