import React from 'react';
import { hot } from 'react-hot-loader/root';
import { ToolTypes } from '../../utils/tools';
import { BrowserView } from 'react-device-detect';
import { ProjectContext } from '../../views/App/App.jsx';
import { Cartesian3, Cartographic, ScreenSpaceEventType, Math as CesiumMath, CameraEventType, TileOrientedBoundingBox, HeadingPitchRange } from 'cesium';
import { Globe, Scene, Cesium3DTileset, LabelCollection, PointPrimitiveCollection, PolylineCollection, Viewer, ScreenSpaceEvent, ScreenSpaceEventHandler, ScreenSpaceCameraController } from 'resium';

/* ---[ Components ]--- */
import Tools from '../Tools/Tools.jsx';
import MeasureTool from '../Tools/MeasureTool.jsx';
import DistanceTool from '../Tools/DistanceTool.jsx';
import ProjectBar from '../ProjectBar/ProjectBar.jsx';

/* ---[ Presentation ]--- */
import Logo from '../../../svg/logo-swescan.svg';
import classNames from 'classnames/bind';
import styles from './Project.module.scss';
const style = classNames.bind(styles);

const Project = () => {
  let viewer = null;
  const project_context = React.useContext(ProjectContext);

  const [position, setPosition] = React.useState({
    latitude: 0,
    longitude: 0,
    altitude: 0,
  });

  /**
   * Callback to handle the loading of the tileset.
   * Zooms the viewer to the tileset bounds.
   * @param tileset
   */
  const handleTilesetLoaded = tileset => {
    if (viewer) {
      const boundingVolume = tileset.root.contentBoundingVolume;
      
      let center = boundingVolume.boundingSphere.center;
      let radius = boundingVolume.boundingSphere.radius;

      const centerPoint = viewer.entities.add({
	position: center,
	point: {
	  pixelSize: 0
	}
      });
	
      viewer.zoomTo(centerPoint, new HeadingPitchRange(0, CesiumMath.toRadians(-20), radius));
    }
  };

  /**
   * Update current geographical position on mouse move.
   * @param mouse_positions
   */
  const handleMouseMove = mouse_positions => {
    if (viewer) {
      const end_position = viewer.scene.pickPosition(mouse_positions.endPosition);
      if (end_position !== undefined) {
        const position = Cartographic.fromCartesian(new Cartesian3(end_position.x, end_position.y, end_position.z));
        setPosition({
          latitude: CesiumMath.toDegrees(position.latitude),
          longitude: CesiumMath.toDegrees(position.longitude),
          altitude: position.height,
        });
      }
    }
  };

  const classes = style('app', {
    'app--move-tool': project_context.state.active_tool === ToolTypes.MOVE_TOOL,
    'app--distance-tool': project_context.state.active_tool === ToolTypes.DISTANCE_TOOL,
    'app--measure-tool': project_context.state.active_tool === ToolTypes.MEASURE_TOOL,
  });

  const instructions = project_context.state.current_project.description
    ? <div className={style('app__instructions')}>{project_context.state.current_project.description}</div>
    : false;

  return (
    <>
      <div className={classes}>
        <ProjectBar title={project_context.state.current_project.title} client={project_context.state.current_project.client} description={project_context.state.current_project.description} date={project_context.state.current_project.date}/>
        <Viewer ref={e => { viewer = e ? e.cesiumElement : null; }} className={style('app__viewer')}>
          <Scene logarithmicDepthBuffer={false} />
          <Globe show={false} />

          <PolylineCollection>
            {project_context.state.polylines}
          </PolylineCollection>

          <PointPrimitiveCollection>
            {project_context.state.points}
          </PointPrimitiveCollection>

          <LabelCollection>
            {project_context.state.labels}
          </LabelCollection>

          <Cesium3DTileset url={project_context.state.current_project.cesium_url ?? 'http://localhost:3000/tileset/tileset.json'} skipLevelOfDetail={true} maximumScreenSpaceError={1} onReady={handleTilesetLoaded} />

          <Tools>
            <DistanceTool />
            <MeasureTool />
          </Tools>

          <BrowserView>
            <ScreenSpaceCameraController tiltEventTypes={CameraEventType.LEFT_DRAG} rotateEventTypes={CameraEventType.RIGHT_DRAG} zoomEventTypes={CameraEventType.WHEEL} />
          </BrowserView>

          <ScreenSpaceEventHandler>
            <ScreenSpaceEvent action={handleMouseMove} type={ScreenSpaceEventType.MOUSE_MOVE} /></ScreenSpaceEventHandler>
        </Viewer>

        {instructions}

        <div className={style('app__position')}>
          <strong className={style('app__positionText')}>Position:</strong> <span>{position.latitude.toFixed(6)}</span>, <span>{position.longitude.toFixed(6)}</span>
        </div>

        <Logo className={style('app__logo')} />
      </div>
    </>
  );
};

export default hot(Project);
