import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PointPrimitive, Polyline, Label } from 'resium';
import { Cartesian2, Cartesian3, Color, Material, Cartographic, Ellipsoid, Math as CesiumMath, EllipsoidGeodesic, HorizontalOrigin, VerticalOrigin } from 'cesium';

/**
 * Generate a unique React key based on the UUID v4 algorithm.
 * @returns {string}
 */
export const createUniqueKey = () => uuidv4();

/**
 * Create a Resium point component.
 * @param position
 * @returns {Point}
 */
export const createPoint = (position) => {
  const key = createUniqueKey();
  return <PointPrimitive color={Color.RED} position={position} key={key} disableDepthTestDistance={Number.POSITIVE_INFINITY} />;
};

/**
 * Create a Resium polyline component.
 * @param type
 * @param positions
 * @returns {Polyline}
 */
export const createPolyline = (type, positions) => {
  const key = createUniqueKey();
  const material = new Material({
    fabric: {
      type: type,
      uniforms: {
        color: Color.RED,
      },
    },
  });

  return <Polyline material={material} positions={positions} width={2} key={key} />;
};

/**
 * Create a Resium label component.
 * @param text
 * @param position
 * @returns {Label}
 */
export const createLabel = (text, position) => {
  const key = createUniqueKey();
  return (
    <Label
      text={text}
      position={position}
      showBackground={true}
      font="14px monospace"
      fillColor={Color.WHITE}
      pixelOffset={new Cartesian2(0, 0)}
      verticalOrigin={VerticalOrigin.CENTER}
      horizontalOrigin={HorizontalOrigin.CENTER}
      disableDepthTestDistance={Number.POSITIVE_INFINITY}
      key={key}
    />
  );
};

/**
 * Update point with latitude/longitude and cartographic data.
 * @param point
 * @returns {Cartesian3}
 */
export const updatePointData = (point) => {
  point.latitude = parseFloat(CesiumMath.toDegrees(point.y));
  point.longitude = parseFloat(CesiumMath.toDegrees(point.x));
  point.cartographic = Ellipsoid.WGS84.cartesianToCartographic(point);
  return point;
};

/**
 * Calculate proper label height offset.
 * @param point_1_geo
 * @param point_2_geo
 * @returns {number}
 */
export const calculateLabelOffset = (point_1_geo, point_2_geo) => {
  if (point_2_geo.height >= point_1_geo.height) {
    return point_1_geo.height + (point_2_geo.height - point_1_geo.height) / 2.0;
  } else {
    return point_2_geo.height + (point_1_geo.height - point_2_geo.height) / 2.0;
  }
};

/**
 * Get the horizontal distance between two points as a string.
 * @param point_1
 * @param point_2
 * @returns {string}
 */
export const getHorizontalDistanceString = (point_1, point_2) => {
  const geodesic = new EllipsoidGeodesic();
  geodesic.setEndPoints(point_1.cartographic, point_2.cartographic);
  const meters = geodesic.surfaceDistance.toFixed(2);
  return meters >= 1000 ? `${(meters / 1000).toFixed(1)} км` : `${meters} м`;
};

/**
 * Get the horizontal distance between two points as a Number.
 * @param point_1
 * @param point_2
 * @returns {Number}
 */
export const getHorizontalDistanceNumber = (point_1, point_2) => {
  const geodesic = new EllipsoidGeodesic();
  geodesic.setEndPoints(point_1.cartographic, point_2.cartographic);
  const meters = geodesic.surfaceDistance;
  return meters;
};

/**
 * Get the vertical distance between two positions as a string.
 * @param point_1_geo
 * @param point_2_geo
 * @returns {string}
 */
export const getVerticalDistanceString = (point_1_geo, point_2_geo) => {
  const heights = [point_1_geo.height, point_2_geo.height];
  const meters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
  return meters >= 1000 ? `${(meters / 1000).toFixed(1)} км` : `${meters.toFixed(2)} м`;
};

/**
 * Get the vertical distance between two positions as a number.
 * @param point_1_geo
 * @param point_2_geo
 * @returns {number}
 */
export const getVerticalDistanceNumber = (point_1_geo, point_2_geo) => {
  const heights = [point_1_geo.height, point_2_geo.height];
  const meters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
  return meters;
};

/**
 * Get the calculated third distance between two positions as a string.
 * @param point_1
 * @param point_2
 * @param point_1_geo
 * @param point_2_geo
 * @returns {string}
 */
export const getDistanceString = (point_1, point_2, point_1_geo, point_2_geo) => {
  const geodesic = new EllipsoidGeodesic();
  geodesic.setEndPoints(point_1.cartographic, point_2.cartographic);
  const horizontal_meters = geodesic.surfaceDistance.toFixed(2);
  const heights = [point_1_geo.height, point_2_geo.height];
  const vertical_meters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
  const meters = Math.pow((Math.pow(horizontal_meters, 2) + Math.pow(vertical_meters, 2)), 0.5);
  return meters >= 1000 ? `${(meters / 1000).toFixed(1)} км` : `${meters.toFixed(2)} м`;
};

/**
 * Get the calculated third distance between two positions as a number.
 * @param point_1
 * @param point_2
 * @param point_1_geo
 * @param point_2_geo
 * @returns {number}
 */
export const getDistanceNumber = (point_1, point_2, point_1_geo, point_2_geo) => {
  const geodesic = new EllipsoidGeodesic();
  geodesic.setEndPoints(point_1.cartographic, point_2.cartographic);
  const horizontal_meters = geodesic.surfaceDistance.toFixed(2);
  const heights = [point_1_geo.height, point_2_geo.height];
  const vertical_meters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
  const meters = Math.pow((Math.pow(horizontal_meters, 2) + Math.pow(vertical_meters, 2)), 0.5);
  return meters;
};

/**
 * Calculate midpoint position from two points and a height.
 * @param point_1
 * @param point_2
 * @param height
 * @returns {Cartesian3}
 */
export const getMidpoint = (point_1, point_2, height) => {
  const scratch = new Cartographic();
  const geodesic = new EllipsoidGeodesic();
  geodesic.setEndPoints(point_1.cartographic, point_2.cartographic);
  const midpoint_cartographic = geodesic.interpolateUsingFraction(0.5, scratch);
  return Cartesian3.fromRadians(midpoint_cartographic.longitude, midpoint_cartographic.latitude, height);
};

/**
 * Calculate triangle angel.
 * @param a
 * @param b
 * @returns {number}
 */
export const getAngleGroundHypotenuse = (a, b) => {
  const angle = Math.acos((a / b)) * (180 / Math.PI);
  return angle.toFixed(1);
};

/**
 * Calculate triangle angel.
 * @param b
 * @param c
 * @returns {number}
 */
export const getAngleVerticalHypotenuse = (b, c) => {
  const angle = Math.acos((c / b)) * (180 / Math.PI);
  return angle.toFixed(1);
};
