import React from 'react';
import PropTypes from 'prop-types';

const Header = (props) => {
  return (
    <section className="hero is-medium is-info is-bold mb-6">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">
            {props.title}
          </h1>
          <h2 className="subtitle">
            {props.text}
          </h2>
        </div>
      </div>
    </section>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Header;
