// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".projectbar__3w6T5{height:4rem;display:flex;padding:0 2.5rem;align-items:center;justify-content:space-between;background:rgba(255,255,255,0.98);position:fixed;top:0;left:0;width:100%;z-index:100;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);border-bottom:1px solid #dbdbdb}.projectbar__titles__3J20b{display:flex;flex-direction:row;align-items:center}.projectbar__customer__1foGp{color:#232323;font-size:1.125rem;margin-right:1.5rem}.projectbar__description__3ckEg{font-weight:300;color:#888}\n", "",{"version":3,"sources":["ProjectBar.module.scss"],"names":[],"mappings":"AAAA,mBAAY,WAAW,CAAC,YAAY,CAAC,gBAAgB,CAAC,kBAAkB,CAAC,6BAA6B,CAAC,iCAAiC,CAAC,cAAc,CAAC,KAAK,CAAC,MAAM,CAAC,UAAU,CAAC,WAAW,CAAC,iCAAyB,CAAzB,yBAAyB,CAAC,+BAA+B,CAAC,2BAAoB,YAAY,CAAC,kBAAkB,CAAC,kBAAkB,CAAC,6BAAsB,aAAa,CAAC,kBAAkB,CAAC,mBAAmB,CAAC,gCAAyB,eAAe,CAAC,UAAU","file":"ProjectBar.module.scss","sourcesContent":[".projectbar{height:4rem;display:flex;padding:0 2.5rem;align-items:center;justify-content:space-between;background:rgba(255,255,255,0.98);position:fixed;top:0;left:0;width:100%;z-index:100;backdrop-filter:blur(5px);border-bottom:1px solid #dbdbdb}.projectbar__titles{display:flex;flex-direction:row;align-items:center}.projectbar__customer{color:#232323;font-size:1.125rem;margin-right:1.5rem}.projectbar__description{font-weight:300;color:#888}\n"]}]);
// Exports
exports.locals = {
	"projectbar": "projectbar__3w6T5",
	"projectbar__titles": "projectbar__titles__3J20b",
	"projectbar__customer": "projectbar__customer__1foGp",
	"projectbar__description": "projectbar__description__3ckEg"
};
module.exports = exports;
