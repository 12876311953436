const projectDraft = {
  client: '',
  title: '',
  url: 'https://3d.swescan.se/',
  description: '',
  date: '',
  password: '',
};

export { projectDraft };
